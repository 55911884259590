*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

  
} 

.hero {
  display: flex;
  height:auto;
  /* width: ; */
  aspect-ratio: 16 / 9; 
  position: relative;
  overflow: hidden;
  background-color: black;
  max-width: 100%;
  background-size: cover; /* Makes the background image cover the entire container */
  background-position: center center;
  /* margin-top: 8%; */
  background-image: url('./Images/Home.jpeg');
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
  .hero {
    background-size: cover; /* Adjust for smaller screens */
    background-repeat: no-repeat;
    background-position: top;
    zoom: normal;
    /* max-width: 100%; */
    /* height: 100vh; */
  }

  .content{
    width: 90%;
    /* margin:8%; */
  }

  .content h1{
    width: 90%;

  }

  .content p{
    max-width: 90%;
  }
}


.content {
  display: flex;
  flex-direction: column;
  max-width: 90%; /* Adjusted for responsiveness */
  margin: 5vw; /* Center horizontally */
  color: black;
  text-align: left;
  justify-content: left;
  align-items: left;
  margin-top: 10%; /*Adjusted for responsiveness*/
}

.content h1 {
  font-size: 5vw; /* Responsive font size */
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  max-width: 40%;
}

.content h1 span {
  color: #71B139;
}

.content p {
  font-size: 1.7vw; /* Responsive font size */
  font-weight: 300;
  line-height: 1.5;
  max-width:40%;
}

.button-container {
  position: relative;
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  justify-content: flex-start; /* Adjust as needed */
}

.button1,
.button2 {
  margin: 1.5%; /* Adjust margins between buttons */
  padding: 1% 2%; /* Adjust padding for buttons */
  border-radius: 10px;
  margin-left: 0%; 
  ; /* Responsive font size */
  transition: 0.4s;
}

.button1 {
  position: relative;
  background-color: #71B139;
  color: white;
  border: none;
  font-size: 2vw
}

.tooltip-text {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;  
  transform: translateX(-50%);
  font-size: 1vw;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.button1:hover .tooltip-text{
  transform: scale(1.04);
  cursor: pointer;
  background-color:#797b77 ;
  visibility: visible;
  opacity: 1;
}

.button2 {
  background-color: transparent;
  color: white;
  border: 1px solid transparent; /* Added border for visibility */
  font-size: 1.3vw;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}

.button2:hover{
  color: black;
  cursor: pointer;
}


/*Jhalak's code  */
.course{
  background-color: black;
}

.subtitle{
  font-size: 5vw;
  font-weight: 600;
  /* font-family: 'Poppins',sans-serif; */
  margin-top: 5vw;
  color: #71b139;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .subtitle {
    font-size: 10vw;
  }
}

.rows{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 3vw 5vw;
}

.col-1{
  flex-basis: 40%;
}

.col-1 img{
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
}

.col-2{
  flex-basis: 52%;
}

.abc{
  color: white;
  /* font-family: 'Poppins',sans-serif; */
  font-size: 1.7vw;
  text-align: justify;
}
@media screen and (max-width: 600px){
  .abc {
    font-size: 3.2vw;
  }
}
.list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5vw;
  justify-content: space-between;
  row-gap: 2vh;
}
.list .mvo-box{
  background: #5c922c; /* Green background */
  padding: 1.5vw;
  font-size: 13px;
  font-weight: 200;
  border-radius: 25px;
  width: 28vw;
  transition: opacity 0.3s ease, background-color 0.3s ease; 
}

.list:hover .mvo-box {
  opacity: 0.5; 
}

.list .mvo-box:hover {
  opacity: 1;
  background-color: #5c922c;
}

.list .mvo-box h1{
  font-size: 4vw;
  color: white;
  font-weight: bold;
  /* font-family: 'Poppins',sans-serif; */
  margin: 1vw;
  text-align: center;
}

.list .mvo-box p{
  color: white;
  /* font-family: 'Poppins',sans-serif; */
  font-size: 1.5vw;
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
  text-align: justify;
}

@media screen and (max-width: 600px){
  .col-1{
      flex-basis: 100%;
  }
  .col-2{
      flex-basis: 100%;
      text-align: center;
  }
  .list .mvo-box {
    width: 90vw;
  }
  .list .mvo-box p {
    font-size: 3vw;
  }
  .list .mvo-box h1 {
    font-size: 6vw;
  }
}

  
/* Dishas Code */

.Carousel{
  height: 100%;
  width: 100%;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  /* min-height: 90vh; */
  /* */
  /* aspect-ratio: 16 / 9;  */
}

.carousel{
  /* aspect-ratio: 16 / 9;  */
  flex: 1;
  padding: 0 3rem;
  background-color: #222222;
  color: #fff;
  /* height: 130vh; */
  width: 98.7vw;
  text-align: center;
  margin-bottom: 10vh;
  /* overflow-x: hidden !important; */
}
/* @media (max-width: 768px) {
  .carousel {
    height: 80vh; /* Adjust height for smaller screens */

.carousel h2{
  text-align: center;
  font-size: 5vw;
  color: #71B139;
  padding: 3vw 0px 5vw 0px;
}

.carousel .box {
  position: relative; /* Ensure the shade-home is positioned relative to this element */
  overflow: hidden;
}

.carousel .box img {
  width: 100%;
  height: auto;
  display: block;
}

.carousel .shade-home {
  text-align: center;
  position: absolute;
  bottom: -40%;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: #222222;
  color: white;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  transition: bottom 0.2s ease;
  z-index: 20;
  opacity: 0.9;
}

.carousel .box:hover .shade-home {
  bottom: 0;
}

@media only screen and (max-width: 500px) {
  .carousel h2 {
    font-size: 10vw;
  }
}

.box {
  width: fit-content;
  height: fit-content;
}


@media screen and (min-width: 1100px){
  .box img{
    height: 25vw !important;
    width: 25vw !important;
  }
  .box{
    width: 25vw !important;
    height: 25vw !important;
    margin: 10px ;
  }
  }
  
  @media screen and (min-width: 700px) and (max-width: 1100px){
    .box img{
      height: 35vw !important;
      width: 35vw !important;
      /* display: flex; */
    }
    .box{
       width: 35vw !important;
      height: 35vw !important;
      margin: 20px !important;
      /* padding: 10px !important; */
    } 
    }
  @media screen and (max-width: 700px){
    .box img{
      height: 60vw !important;
      width: 60vw !important;
    }
    .box{
      /* margin: 1rem; */
       width: 60vw !important;
      height: 60vw !important;
     margin: 10px !important;
     /* padding: 10px !important; */

    } 
    }



button.slick-arrow.slick-prev{
width: 30px;
height: 30px;
}
button.slick-arrow.slick-prev::before{
  width: 30px;
  height: 30px;
  font-size: 30px;
  }
  button.slick-arrow.slick-next{
    width: 30px;
    height: 30px;
    }
    button.slick-arrow.slick-next::before{
      width: 30px;
      height: 30px;
      font-size: 30px;
      }
      .slick-dots li.slick-active button:before{
        color: #fff !important;
        opacity: 1 !important;
      }
      .slick-dots li button:before{
        color: #fff !important;
        opacity: .25 !important;
      } 



/*
.banner {
  background-image: url('../Events/FinWeek25/FINWEEK.png'); 
  background-size: cover;
  background-position: center;
  text-align: center;
  aspect-ratio: 2.5;
  position: relative; 
}

.banner-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-button {
  background-color: #d3d957; 
  color: rgb(0, 0, 0);
  border: none;
  padding: 15px 30px; 
  font-size: 1.5rem; 
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: absolute;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
}

.banner-button:hover {
  background-color: #b5c44a; 
}

@media (max-width: 768px) {

  .banner-button {
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .banner-button {
    font-size: 0.7rem;
    padding: 8px 16px;
  }
}
*/