
.cores-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5vw;
    row-gap: 5vw;
    padding: 2vw;
    text-align: center;
    position: relative;
}

.photo {
    width: 25vw;
    object-fit: cover;
    justify-self: center;
    position: relative;
}

.image {
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
}

.overlay23 {
    position: absolute; 
    height: 3.55vw;
    display: flex;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity: 0;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
}

.overlay23 i {
    height: 80%;
    color: #dbdbdb;
}

.photo:hover .image,
.photo.touch .image {
    opacity: 0.5;
}

.photo:hover .overlay23,
.photo.touch .overlay23 {
    opacity: 1;
}

.cores {
    display: block;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-size: xx-large;
    color: #fff1f1;
    background-color: #010100;
    text-align: center;
    padding-top: 5vh;
    height: auto;
}

.cores h2 {
    font-size: 1.2em;
    font-weight: normal;
}


@media (max-width: 900px) {
    .cores-photo {
        grid-template-columns: repeat(2, 1fr);
    }
  
    .photo {
        width: 40vw;
    }
  
    .overlay23 {
        height: 5.6vw;
    }
  
    .overlay23 i {
        height: 2.5vw;
    }
}

@media (max-width: 600px) {
    .cores-photo {
        grid-template-columns: repeat(1, 1fr);
    }
  
    .photo {
        width: 80vw;
    }
  
    .overlay23 {
        height: 11.5vw;
    }
  
    .overlay23 i {
        height: 5vw;
    }
  
}