.event-events-header {
  background-image: url('../Images/bgfortabs.jpg');
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  padding: 20px;
  text-align: center;
  height: 200px; 
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  margin: auto;  
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
}

.event-events-header h1 {
  font-size: 2.2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.event-events-header p {
  margin-top: 10px; 
  font-size: 1em; 
  text-align: center;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.event-Year {
  font-size: 3em;
  margin: 20px 9vw;
  text-align: center;
  color: #fff;
  text-decoration: underline #5f8e33;
}

.event-divs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 5vw;
}

.event-flex1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 2vh;
  transition: transform 0.3s ease;
}

.event-element {
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 40vw;
}

.event-background-box {
  position: absolute;
  top: 0; /* Adjust the position as needed */
  right: 0; /* Adjust the position as needed */
  width: 35vw; /* Match the width of the event-element */
  height: 35vw; /* Match the height of the event-element */
  z-index: -1; /* Ensure it is behind the event-element */
}

.event-image-box {
  position: absolute;
  bottom: 0;
  left: 0;
}

.event-flex1:hover {
  transform: scale(1.05);
}

.event-element img {
  width: 35vw;
  height: 35vw;
  display: block;
}

.event-title {
  font-size: 1.5em;
  margin: 10px 0;
  text-align: center;
  color: #fff;
  padding-right: 5vw;
}

.event-green-line {
  position: relative;
  height: 0.1px;
  width: 80vw;
  background-color: #5f8e33;
  margin: 10vh 10vw;
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 12vh 12vw;
}

.finweek-banner {
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.finweek-banner:hover {
  transform: scale(1.05);
}

.event-divs.finweek-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.event-divs.finweek-grid .span-two {
  grid-column: span 2;
}

.banner-arrow {
  border-left: 5vw solid transparent;
  border-right: 5vw solid transparent;
  border-top: 5vw solid #d3d957;
  transition: transform 0.3s ease; 
  margin-bottom: 3vw;
  position: absolute;
}

.banner-arrow-rotate {
  transform: rotate(180deg); 
}

@media (max-width: 600px) {
  .event-divs {
      grid-template-columns: 1fr; /* Single column grid */
  }

  .event-divs.finweek-grid {
    grid-template-columns: 1fr; /* Single column grid */
  }

  .event-divs.finweek-grid .span-two {
    grid-column: 1 / -1;
  }

  .event-element {
    width: 70vw;
    height: 70vw;
  }

  .event-element img {
    width: 60vw; /* Set the box size to 75vw */
    height: 60vw; /* Set the box size to 75vw */
}

  .event-background-box {
      width: 60vw; /* Match the width of the event-element */
      height: 60vw; /* Match the height of the event-element */
  }

  .event-Year {
    margin: 20px 18vw;
  }
}

@media (min-width: 1000px) {

  .event-element {
    width: 30vw;
    height: 30vw;
  }

  .event-element img {
    width: 25vw; /* Set the box size to 75vw */
    height: 25vw; /* Set the box size to 75vw */
}

  .event-background-box {
      width: 25vw; /* Match the width of the event-element */
      height: 25vw; /* Match the height of the event-element */
  }
  
  .event-Year {
    margin: 20px 12vw;
  }
}