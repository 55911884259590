#progress-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1000;
}

#progress-bar {
    height: 100%;
    width: 0;
    background-color: #5f8e33;
}