.finweek25 {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.banner {
  width: 100%;
  aspect-ratio: 2.5;
  overflow: hidden;
  background-image: url('./FINWEEK.png');
  background-size: cover;
  align-content: end;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: relative;
}

.arrow-container .arrows-pulse i.animated {
  -webkit-transition-property: -webkit-transform;
  transition-property: all;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -moz-animation-name: pulse;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -moz-animation-timing-function: linear;
}

.arrow-container .arrows-pulse:hover i.animated {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -moz-animation-name: pulse;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
}


.arrows-pulse.delay-1 i.animated {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.arrows-pulse.delay-2 i.animated {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.arrows-pulse {
  border-radius: 60px;
  font-size: 1.2rem;
  color: #d3d957;
  -webkit-transition: background .4s ease-in-out;
  -moz-transition: background .4s ease-in-out;
  transition: background .4s ease-in-out;
}

.arrows-pulse.delay-1 {
  font-size: 1.6rem;
}

.arrows-pulse.delay-2 {
  font-size: 2rem;
}

.arrows-pulse:hover, .arrows-pulse:focus {
  outline: 0;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
  
.timeline-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: center;
}

  .timeline-title {
    text-align: center;
    font-size: 2.5rem;
    margin: 20px 0;
    color: #d3d957
  }

  
  .timeline-landmark {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
  }
  
  .ludo-piece {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .oval-base {
    width: 60px;
    height: 30px;
    background-color: #d3d957;
    border-radius: 25%;
    bottom: 0;
    position: relative;
    z-index: 2;
  }
  
  .frustum {
    width: 30px;
    height: 50px;
    background-color: #d3d957;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    position: absolute;
    bottom: 30px;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: #d3d957;
  border-radius: 50%;
  position: absolute;
  bottom: 80px;
}

.timeline-line {
  width: 2vh;
  height: 5vh;
  background-color: #5f8e33;
  margin-top: 5px;
  margin-bottom: -2vh;
}
  
.timeline {
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-line-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2vh;
  height: 2vh;
  background-color: #5f8e33;
  z-index: -1;
}


.timeline-box-container {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -1.95vh;
}
  

@media screen and (max-width: 768px) {
  .timeline-box-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .timeline-box {
    width: 90%;
  }
}

  .timeline-box {
    margin: 0;
    padding: 0;
    width: 40vw;
    position: relative;
    align-items: center;
    justify-items: center;
  }
  
  .timeline-box.left.left-line {
    border-left: 2vh solid #5f8e33;
    border-top: 2vh solid #5f8e33;
    border-bottom: 2vh solid #5f8e33;
    border-right: none; 
    margin-top: 0.02vw;
    border-radius: 1vw 0 0 1vw;
    background-color: rgba(1, 1, 1, 0.8); 
  }

  .timeline-box.right.right-line {
    border-right: 2vh solid #5f8e33;
    border-top: 2vh solid #5f8e33;
    border-bottom: 2vh solid #5f8e33;
    border-left: none; 
    background-color: transparent;
    border-radius: 0 1vw 1vw 0;
  }

  
  .timeline-box.right.event4 {
    margin-top: 0.04vw;
  }
  
  .circle {
    width: 3vh;
    height: 3vh;
    background-color: #d3d957;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .timeline-box.left .circle {
    left: -0.8vh;
    top: 50%;
  }

  .timeline-box.right .circle {
    right: -4vh;
    top: 50%;
  }

  .empty-box {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  
  .timeline-image {
    height: 30vh;
    object-fit: cover;
  }

  .timeline-box p {
    margin: 0 2vw;
    text-align: center;
    font-size: 1.15rem;
  }


  .event-date-day {
    display: block;
    justify-content: space-between;
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #83976d;
  }
  
  .event-name {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #f4fceb;
    text-align: center;
  }

  p.event-name {
    color: #83976d;
    font-size: 0.9rem;
  }
  
  .event-venue,
  .event-participation {
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: #f6edd9;
    text-align: center;
  }
  
  .event-button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #5f8e33;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .event-button:hover {
    background-color: #83976d;
  }

  .event-link {
    display: block;
    margin-bottom: 5px;
    color: #5f8e33;
    text-decoration: none;
    text-align: center;
  }

.event-link:hover {
  text-decoration: underline;
}

.last-box {
  margin-bottom: -0.7vw;
}

  
@media screen and (max-width: 768px) {
  .arrows-pulse {
    font-size: 0.4rem;
  }

  .arrows-pulse.delay-1 {
    font-size: 0.8rem;
  }

  .arrows-pulse.delay-2 {
    font-size: 1.2rem;
  }

  .timeline-box-container {
    display: flex;
    flex-direction: column;
  }

  .timeline-box {
    width: 80vw;
    display: flex;
    flex-direction: column;
  }

  .timeline-box.left.left-line {
    border: none;
  }

  .timeline-box-container.cont-left {
    border-left: 2vh solid #5f8e33;
    border-top: 2vh solid #5f8e33;
    border-bottom: 2vh solid #5f8e33;
  }

  .timeline-box-container.cont-left.finweek-first {
    position: relative;
    border-top: none;
  }

  .timeline-box-container.cont-left.finweek-first::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 2vh;
    border-radius: 0 0 2vh 0;
    background-color: #5f8e33;
    z-index: 1;
  }

  .timeline-box-container.cont-left.finweek-last {
    position: relative;
    border-bottom: none;
  }
  
  .timeline-box-container.cont-left.finweek-last::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 2vh;
    background-color: #5f8e33;
    z-index: 1;
  }

  .timeline-box.right.right-line {
    border: none;
  }

  .timeline-box-container.cont-right {
    border-right: 2vh solid #5f8e33;
    border-bottom: 2vh solid #5f8e33;
    flex-direction: column-reverse;
  }

  .timeline-box-container.cont-right .circle {
    top: 100%;
  }

  .timeline-box-container.cont-left .circle {
    top: 100%;
  }
}

@media screen and (min-width: 1208px) {
  .arrows-pulse {
    font-size: 2rem;
  }

  .arrows-pulse.delay-1 {
    font-size: 2.4rem;
  }

  .arrows-pulse.delay-2 {
    font-size: 2.8rem;
  }
  
}
