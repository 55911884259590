.resources {
  background-color: black;
}

.header {
  background-image: url('../Images/bgfortabs.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 20px;
  text-align: center;
  height: 200px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;  
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
}

.blogs{
  background-image: url('./Images/blog_bg.jpg');
}
.brs{
  background-image: url('./Images/book review_bg.jpg');
}
.yt{
  background-image: url('./Images/video_bg.jpg');
}
.pc{
  background-image: url('./Images/podcast_bg.jpg');
}
.sm{
  background-image: url('./Images/stock market_bg.jpg');
}
.tap{
  background-image: url('./Images/trading apps_bg.jpg');
}
.moneymc {
  background-image: url('./Images/MoneyMasterclasscopy.png');
  background-position: bottom;
}


.header h1 {
  font-size: 2.2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.box-main h1 {
  font-size: 2.2em;
  font-weight: 500; 
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);
}

.header p {
  margin-top: 10px; 
  font-size: 1em; 
  text-align: center;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.resourcesbox{
  display: flex;
  /* margin: auto; */
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2vw;
}

.boxsmall {
  padding: 2vw;
  box-sizing: border-box;
  width: 26vw;
  height: auto;
  border-radius:5px;
  background-color: rgb(31, 30, 30);
  margin: 1vw;
  text-decoration: none;
  transition: 0.3s ease-out;
}

.boxsmall img{
  height: 15rem;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(9, 11, 10, 0.7);
}
.boxsmall:hover{
  transform: translatey(-10px);
}

.review img {
  height: auto;
  aspect-ratio: 0.622;
}

.landscape img {
  height: auto;
  aspect-ratio: 1.5;
  margin-bottom: 0;
}

.podcast img {
  height: auto;
  aspect-ratio: 1;
}

.blog :hover {
  cursor: pointer;
}

.boxsmall h2{
  color: white;
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0;
  text-decoration: none;
  min-height: 2.8rem; 
  height: auto;
}

.boxsmall p{
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 0.9rem;
  /* padding-top: 1rem; */
  text-decoration: none;
}

.boxsmall a {
  text-decoration: none;
  color: rgb(141, 141, 141);
}

.podcast-text {
  justify-items: center;
}

.bookreview .boxsmall:hover,
.Youtube .boxsmall:hover{
    transform: scale(0.98);
}
.Youtube .links:hover{
  text-decoration: underline;
}

.tradingapps p {
  color: #c0c0c0;
}
.tradingapps i {
  text-align: center;
  justify-self: center;
  color: #FFFFFF;
}
.boxsmall i {
  font-size: 1.2rem;
}

@media only screen and (max-width: 1064px) {
  .boxsmall {
    width: 32vw;
    margin: 2vw;
  }
  .resourcesbox{
    justify-content: center;
    padding: 4vw;
  }
}

@media only screen and (max-width: 844px) {
  .boxsmall {
    width: 38vw;
    margin: 2vw;
  }
  .resourcesbox{
    justify-content: center;
    padding: 4vw;
  }
}

@media only screen and (max-width: 700px) {
  .boxsmall {
    width: 50vw;
    margin: 5vw;
  }
}

@media only screen and (max-width: 570px) {
  .boxsmall {
    width: 80vw;
    margin: 5vw;
  }
}
