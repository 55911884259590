
.cores-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5vw;
    row-gap: 5vw;
    padding: 2vw;
    text-align: center;
    position: relative;
}

.team24-photo {
    width: 25vw;
    object-fit: cover;
    justify-self: center;
    position: relative;
    transition: transform 0.3s ease; 
}

.team24-photo:hover {
    transform: scale(1.05); /* Zoom in on hover */
}

.team24-image {
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    z-index: 1;
}

.team24-image-anim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease; 
    z-index: 2;
}

.team24-photo:hover .team24-image-anim {
    opacity: 1;
}

.overlay24 {
    position: absolute; 
    height: 3.1vw;
    display: flex;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); 
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity: 0;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    z-index: 3;
}

.team24-photo:hover .overlay24 {
    opacity: 1; 
}

.overlay24 i {
    height: 50%;
    color: #dbdbdb;
}

.team24-icons-line {
    height: 3vh;
    background: rgba(0, 0, 0, 0.5);
}

.team24-icons {
    font-size: 2vw;
    z-index: 4;
    transition: transform 0.3s ease;
}

.team24-photo:hover .team24-icons {
    animation: bob 0.8s ease-in-out 2; /* Apply bobbing animation */
}

@keyframes bob {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Adjust the bobbing distance */
    }
    100% {
        transform: translateY(0); /* Return to original position */
    }
} 

.team24-photo:hover .team24-image {
    opacity: 0.5;
}

.team24-photo:hover .overlay24 {
    opacity: 1;
}

.cores {
    display: block;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-size: xx-large;
    color: #fff1f1;
    background-color: #010100;
    text-align: center;
    padding-top: 5vh;
    height: auto;
}

.cores h2 {
    font-size: 1.2em;
    font-weight: normal;
}


@media (max-width: 900px) {
    .cores-photo {
        grid-template-columns: repeat(2, 1fr);
    }
  
    .team24-photo {
        width: 40vw;
    }
  
    .overlay24 {
        height: 4.9vw;
    }
  
    .overlay24 i {
        height: 2.5vw;
    }

    .team24-icons {
        font-size: 3vw;
    }
}

@media (max-width: 600px) {
    .cores-photo {
        grid-template-columns: repeat(1, 1fr);
    }
  
    .team24-photo {
        width: 80vw;
    }
  
    .overlay24 {
        height: 9.77vw;
    }
  
    .overlay24 i {
        height: 5vw;
    }

    .team24-icons {
        font-size: 5vw;
    }
  
}